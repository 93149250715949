@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cloud-wcontainer {
  padding: 0 !important;
}
.g2-tooltip {
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
}
.ant-badge-status-text {
  color: white;
}

.customTooltip {
  padding: 12px;
  font-size: 14px;
  line-height: 14px;
  color: white;

  .toolTitle {
    margin-bottom: 10px;
  }

  .toolContent {
    line-height: 1.3;
    padding-left: 8px;
    :global(.ant-badge-status-text) {
      font-size: 14px;
      color: white;
    }
  }
}

@primary-color: #1DA57A;